import React from "react";
import { createUseStyles } from "react-jss";
import Flex from "./flex";
import Loader from "./loader";

const useStyles = createUseStyles(() => ({
  containerClass: {
    height: "100%",
  },
}));
const LazyLoad = (props: {
  children: React.ReactNode;
  overrideClass?: string;
}) => {
  const { children, overrideClass = "" } = props;
  const classes = useStyles();
  return (
    <React.Suspense
      fallback={
        <Flex
          overrideClass={`${classes.containerClass} ${overrideClass}`}
          justify="center"
          align="center"
        >
          <Loader loaderMsg="Loading..." />
        </Flex>
      }
    >
      {children}
    </React.Suspense>
  );
};

export default LazyLoad;
