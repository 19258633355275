import moment from "moment";
export const getPathData = (
  obj: object,
  [...args]: any[],
  defaultValue: any = null,
) => {
  const result = args.reduce((obj, level) => obj && obj[level], obj);
  return result || defaultValue;
};
export const validateRegex = (string = "", expressions = []) => {
  for (const value of expressions) {
    if (!string.match(value)) {
      return false;
    }
  }
  return true;
};

export const validateEmail = (value = "") => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return validRegex.test(value);
};
export const validateMobile = (value = "") => {
  const regexExp = /^[6-9]\d{9}$/gi;
  return regexExp.test(value);
};
export const isNumber = (value = 0) => {
  return !isNaN(value);
};
export const dtutil = (value = "") => {
  return moment(value);
};
export const isEmptyObject = (obj = {}) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};
export const truncateText = (text: string, maxLength: number) => {
  if (text?.length <= maxLength) {
    return text;
  }
  return text?.slice(0, maxLength) + "...";
};

export const interestRateConverter = (num: number) => {
  if (!num) {
    return "NA";
  } else if (num < 1) {
    return `${num * 100} %`;
  } else {
    return `${num}%`;
  }
};

export const formatValue = (value: number) => {
  if (value === null || value === undefined) {
    return "0";
  }
  const numericValue = typeof value === "string" ? Number(value) : value;
  if (numericValue === 0) {
    return "0";
  }
  const formattedValue =
    numericValue % 1 !== 0 ? numericValue.toFixed(3) : String(numericValue);
  return formattedValue;
};

export const buildURL = (baseUrl = "", obj = {}) => {
  let url = baseUrl + "?";

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      //@ts-ignore
      obj[key] !== undefined &&
      //@ts-ignore
      obj[key] !== null &&
      //@ts-ignore
      obj[key] !== ""
    ) {
      url +=
        encodeURIComponent(key) +
        "=" +
        encodeURIComponent(
          //@ts-ignore
          obj[key],
        ) +
        "&";
    }
  }

  if (url.endsWith("&")) {
    url = url.slice(0, -1);
  }

  return url;
};

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const convertStatus = (status: string) => {
  return status
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const useDebouncedCallback = (func: () => void, wait: number) => {
  let timer: any;
  return function (
    //@ts-ignore
    ...args
  ) {
    //@ts-ignore
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      // timer = null;
      //@ts-ignore
      func.apply(context, args);
    }, wait);
  };
};

export const validateMsisdn = ({
  msisdn,
  maxMsisdnNumber,
  required = true,
  countryCode = "+256",
}: {
  msisdn: string;
  maxMsisdnNumber: number;
  required?: boolean;
  countryCode: string;
}): string => {
  if (!required && !msisdn) return "";
  if (msisdn === "") return "Please enter Mobile Number";
  if (msisdn && msisdn?.length >= 2) {
    if (msisdn?.length >= 3) {
      const prefix = msisdn?.slice(0, 3);
      if (prefix === "000") return "Enter valid  number";
    }
  }
  if (msisdn && msisdn?.length !== maxMsisdnNumber)
    return "Please enter a valid Mobile Number";
  const firstDigit = msisdn[0];
  if (firstDigit === "0") return 'Enter number without "0" at the beginning';
  // if (allowedStartingDigits && allowedStartingDigits?.indexOf(firstDigit) === -1)
  //     return 'Please enter a valid Mobile Number';
  return "";
};

export const base64UrlEncode = (input: string) => {
  return btoa(input);
};

export const encodeDataHandler = (encData: object) => {
  try {
    return base64UrlEncode(JSON.stringify(encData));
  } catch (err) {
    return err;
  }
};

export const decodeDataHandler = (encData: string) => {
  return new Promise((resolve, reject) => {
    try {
      const decryptedData = atob(encData)?.toString();
      resolve(JSON.parse(decryptedData));
    } catch (err) {
      reject(err);
    }
  });
};

export default {
  getPathData,
  validateRegex,
  validateEmail,
  validateMobile,
  isNumber,
  dtutil,
  isEmptyObject,
  isJson,
  truncateText,
  formatValue,
  buildURL,
  capitalizeFirstLetter,
  useDebouncedCallback,
  validateMsisdn,
};
