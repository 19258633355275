// primary: '#FFAC2F',
// primaryLight: 'rgba(245, 108, 28, 0.20)',
// secondary: '#1F0659',
// indigo: '#20075A',
export const Colors = {
  PRIMARY_LIGHT: "rgba(245, 108, 28, 0.20)",
  PRIMARY_DARK: "#0F3471",
  PRIMARY: "#FFAC2F",
  SECONDARY: "#1F0659",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  RED: "#CC0033",
  GREY: "#C8C8C8",
  LIGHT_GREY: "#D3D3D3",
  GREY_90: "#f0f0f0",
  LABEL_COLOR: "#1F0659",
  LIGHTBLUE: "#ADD8E6",
  YELLOW: "#FFEA00",
  GREY1: "#0000004D",
  // BTN_PRIMARY: "#0d5bc6",
  FORM_TEXT_COLOR: "#202124",
  DARK_GREY: "#282A36",
  GREY_20: "#EEEEEE",
  GREY_30: "#F8F9FA",
  UNSELECTED: "#666",
  BORDERCOLOR: "#E3E2E9",
  GREEN: "#50C878",
  LINK: "#00000066",
  FOOTER_BG: "#060A0D",
  GOOTER_LINK: "#E6E7E880",
  OVERLAY: "rgba(31, 32, 41, 0.75)",
  RED_20: "#F48B8B",
  RED_30: "#FF9696",
  ORANGE_20: "#FFCDBD",
  ORANGE: "#F0A871",
  BROWN: "#844A1D",
  GREEN_20: "#D6EEC8",
  GREEN_30: "#3E8E39",
  GREEN_40: "#006400",
  GREY_40: "#a9a9a9",
  YELLOW_20: "#FFF8E1",
  BLUE_20: "#deedfa",
  ERROR_RED: "#710C05",
  GREY_10: "#3A363F",
  BUTTONDISABLED: "#ABA7AF",
  GREY900: "#0C0911",
  BORDERCOLOR_10: "#F2F2F3",
  TEXTCOLOR: "#6B6572",
  GREY_50: "#29242D",
  GREY_60: "#E1DFE2",
  GREY_70: "#f5f5f9",
  BLUE_10: "#696cff",
  DISABLED: "#C7C5C9",
  ORANGE100: "",
  GREY200: "",
  RED300: "",
  RED600: "#C7C5C9",
};

export default Colors;
