import React, { useState, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import Theme from "Global/theme";
import Colors from "Global/colors";
import { getPathData } from "Utils/helpers";
import Label from "./label";
import Flex from "./flex";
import Typography from "./typography";
import PropTypes from "prop-types";
import { DownAngleArrow, CloseIcon as Close, StraightLine } from "Assets";
import { SelectBoxOptionType, SelectBoxType } from "./componentTypes";
const useStyles = createUseStyles((theme: any) => ({
  mainContainer: {
    width: "100%",
    height: "100%",
    padding: `${theme.padding / 2}px ${theme.padding + 6}px`,
    minHeight: "38px",
    boxSizing: "border-box",
    border: `1px solid ${Colors.BORDERCOLOR}`,
    transition: "0.1s",
    outline: "none",
    borderRadius: theme.borderRadius,
    color: Colors.SECONDARY,
    textIndent: 3,
    fontFamily: "Poppins-Regular",
    // @ts-expect-error
    cursor: (props) => (props.disabled ? "default" : "pointer"),
    backgroundColor: Colors.WHITE,
  },

  borderRed: {
    border: `1px solid ${Colors.RED}`,
  },

  optionContainer: {
    width: "100%",
    position: "absolute",
    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.GREY}`,
    borderRadius: theme.borderRadius,
    zIndex: 222,
    top: "100%",
    minHeight: 60,
    left: 0,
    marginTop: theme.margin / 2,
    // @ts-expect-error
    maxHeight: (props) => props?.dropDownHeight,
    overflow: "auto",
  },

  optionClass: {
    padding: theme.padding - 2,
    cursor: "pointer",
    color: Colors.SECONDARY,
    "&:hover": {
      backgroundColor: Colors.BLUE_20,
      color: Colors.SECONDARY,
    },
  },
  selectedOptionClass: {
    backgroundColor: theme?.primaryColor,
    color: Colors.WHITE,
  },
  container: {
    border: "none",
    width: "100%",
    position: "relative",
  },

  inputClass: {
    border: "none",
    transition: "0.1s",
    outline: "none",
    width: "inherit",
    color: Colors.SECONDARY,
    "&:focus": {
      border: "none",
    },
    "&::placeholder": {
      fontSize: "12px",
      color: (
        props, // @ts-expect-error
      ) => (props?.showPlaceHolder ? "" : Colors.SECONDARY),
    },
  },
  selectOptionStyle: {
    backgroundColor: Colors.GREY_20,
    color: Colors.SECONDARY,
    marginLeft: 3,
    marginBottom: 3,
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    height: 20,
    boxSizing: "border-box",
    borderRadius: theme.borderRadius / 2,
  },

  selectedLabel: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    padding: "0px 3px 2px 0px",
    ...Theme.typography.p,
    fontSize: 14,
    fontFamily: "Times New Roman",
  },
  noOptionsClass: {
    paddingTop: 10,
    textAlign: "center",
    fontSize: 14,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },

  closeIconStyle: {
    width: 15,
    padding: theme.padding / 2,
    cursor: "pointer",
    zIndex: 22,
    "&:hover": {
      borderRadius: theme.borderRadius / 2,
      backgroundColor: Colors.RED_30,
    },
  },

  arrowStyle: {
    width: "13px",
    height: "13px",
    marginRight: (
      props,
      // @ts-ignore
    ) => (props.isMulti ? "" : `${theme.margin * 2}%`),
    marginLeft: (
      props,
      // @ts-ignore
    ) => (props.isMulti ? `${theme.margin * 2}%` : ""),
    opacity: (
      props,
      // @ts-ignore
    ) => (props?.disabled ? 0.3 : 0.5),
    "&:hover": {
      opacity: (
        props,
        // @ts-ignore
      ) => (props?.disabled ? 0.3 : 1),
    },
  },

  multiCloseIconStyle: {
    width: "15px",
    height: "15px",
    marginRight: `${theme.margin + 2}%`,
    // marginTop: `${theme.margin - 2}%`,
    // @ts-expect-error
    opacity: (props) => (props?.disabled ? 0.3 : 0.5),
    "&:hover": {
      opacity: (
        props,
        // @ts-ignore
      ) => (props?.disabled ? 0.3 : 1),
    },
  },

  disabledCheckbox: {
    backgroundColor: Colors.GOOTER_LINK,
  },

  typographyStyle: {
    fontSize: 12,
    marginLeft: theme.margin,
  },

  selectBoxContainer: {
    border: `2px solid ${theme?.primaryColor}`,
  },
  arrowClass: {
    height: 20,
    borderLeft: `1px solid ${Colors.BORDERCOLOR}`,
    position: "absolute",
    right: -10,
    top: "50%",
    transform: "translateY(-50%)",
    paddingLeft: theme.padding * 1.5,
    "& img": {
      width: theme.padding * 2,
    },
  },
}));

const SelectBox = (props: SelectBoxType) => {
  const {
    options = [],
    value = [],
    handleChange = null,
    label = "",
    errorMsg = "",
    helpText = "",
    placeholder = "",
    overrideMainContainerClass = "",
    containerClass = "",
    overrideOptionContClass = "",
    overrideOptionClass = "",
    dropDownHeight = 190,
    overrideMainContainerStyle = {},
    overrideOptionContStyle = {},
    overrideCloseIconClass = "",
    overrideArrowClass = "",
    containerStyle = {},
    labelStyle = {},
    overrideSelectOptionClass = "",
    overrideSelectLabelClass = {},
    overrideTypographyStyle = "",
    overrideStraightLineStyle = {},
    showLabel = true,
    error = false,
    disabled = false,
    required = false,
    isMulti = false,
    isSearchable = true,
    isCustom = false,
    labelKey = "label",
    valueKey = "value",
    helpTextColor = Colors.RED,
    extraRefreshProps = [],
    inputType = "array_value",
    responseType = "array_value",
    parentContainerStyle = {},
    parentContainerClass = "",
    disabledColor = "",
    testID = "selectbox_input",
    ...rest
  } = props;
  const inputRef = useRef(null);
  const [selectArray, setSelectArray] = useState([...options]);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<SelectBoxOptionType[]>(value);
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles({
    ...props,
    //@ts-ignore
    dropDownHeight,
    showPlaceHolder: !isMulti && !selectedOption?.length,
  });

  const {
    mainContainer,
    optionClass,
    selectedLabel,
    optionContainer,
    selectedOptionClass,
    inputClass,
    noOptionsClass,
    closeIconStyle,
    arrowStyle,
    selectOptionStyle,
    typographyStyle,
    selectBoxContainer,
  } = classes;

  useEffect(() => {
    setSearchValue("");
  }, [...extraRefreshProps]);
  useEffect(() => {
    setSelectArray(options);
  }, [props.options, ...extraRefreshProps]);

  useEffect(() => {
    if (inputType === "single_value") {
      const result = options.filter(
        (
          res: SelectBoxOptionType, //@ts-ignore
        ) => res?.[valueKey] === value,
      );
      setSelectedOption(result);
    } else setSelectedOption(value);
  }, [props?.value, ...extraRefreshProps]);
  const toggleOption = () => {
    setIsOptionsVisible((prev) => !prev);
  };

  const onOptionClicked = (resp: SelectBoxOptionType) => {
    setSearchValue("");
    setIsOptionsVisible(false);
    if (isMulti) {
      const cloneOptionsArray = [...selectedOption];
      cloneOptionsArray.push(resp);

      setSelectedOption(cloneOptionsArray);
      const updateSelectedOption = [];
      for (const obj1 of options) {
        let isValid = true;
        for (const obj2 of cloneOptionsArray) {
          //@ts-ignore
          if (obj1[valueKey] === obj2[valueKey]) {
            isValid = false;
            break;
          }
        }
        if (isValid) updateSelectedOption.push(obj1);
      }
      setSelectArray(updateSelectedOption);
      if (handleChange) {
        if (responseType === "single_value") {
          handleChange({
            res: cloneOptionsArray.map(
              (
                result, //@ts-ignore
              ) => result[valueKey],
            ),
          });
        } else {
          handleChange({ res: cloneOptionsArray });
        }
      }
    } else {
      setSelectedOption([resp]);
      setIsOptionsVisible(false);
      if (handleChange) {
        if (responseType === "single_value") {
          handleChange({
            //@ts-ignore
            res: [resp[valueKey]],
          });
        } else {
          handleChange({ res: [resp] });
        }
      }
    }
  };
  const onChangeHandler = (res: string) => {
    const resp = res ? res?.trimStart() : "";
    if (resp === "" && searchValue === "") {
      return;
    }
    if (isSearchable) {
      if (searchValue === "") {
        setIsOptionsVisible(true);
      }

      if (searchValue !== "" && resp.length == 1) {
        setSelectArray(options);
      } else {
        setSelectArray(
          options.filter((result) =>
            //@ts-ignore
            result[labelKey]?.toLowerCase().includes(resp?.toLowerCase()),
          ),
        );
      }
      setSearchValue(resp ? resp?.trimStart() : "");
    }
  };

  const deletehandler = ({ res }: { res: string }) => {
    const filteredOptions = selectedOption.filter(
      (resp) => res !== getPathData(resp, [valueKey], null),
    );
    setSelectedOption(filteredOptions);
    if (handleChange) {
      if (responseType === "single_value") {
        handleChange({
          res: filteredOptions.map(
            (
              result, //@ts-ignore
            ) => result[valueKey],
          ),
        });
      } else {
        handleChange({ res: filteredOptions });
      }
    }
  };
  const onSubmitHandler = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const val = searchValue;
    if (isMulti && isCustom) {
      if (val !== "") {
        setSelectArray(options);
        onOptionClicked(
          //@ts-ignore
          {
            [labelKey]: val,
            [valueKey]: val,
          },
        );
        if (handleChange) {
          if (responseType === "single_value") {
            handleChange({
              res: [
                ...selectedOption.map(
                  (
                    result, //@ts-ignore
                  ) => result[valueKey],
                ),
                val,
              ],
            });
          } else {
            handleChange({
              res: [...selectedOption, { [labelKey]: val, [valueKey]: val }],
            });
          }
        }
      }
    } else if (isCustom) {
      if (val !== "") {
        setSearchValue("");
        if (handleChange) {
          if (responseType === "single_value") {
            handleChange({
              res: [val],
              typedData: val,
            });
          } else {
            handleChange({
              res: [{ [labelKey]: val, [valueKey]: val }],
              typedData: { [labelKey]: val, [valueKey]: val },
            });
          }
        }
      }
    }
  };
  const getLeftComponent = () => {
    return (
      <>
        {selectedOption.map((res, key) => {
          const label = getPathData(res, [labelKey], "");
          return (
            <div
              className={`${overrideSelectOptionClass} ${selectOptionStyle}`}
              key={key}
            >
              <Typography
                overrideClass={`${overrideSelectLabelClass} ${selectedLabel}`}
              >
                {label}
              </Typography>
              <Close
                data-action="close"
                data-id={res?.value}
                className={`${closeIconStyle} ${overrideCloseIconClass}`}
                onClick={() => {
                  deletehandler({ res: getPathData(res, [valueKey], null) });
                }}
                data-testid={`selectbox_option_delete_${key}`}
              />
            </div>
          );
        })}
      </>
    );
  };
  const getRightComponent = () => {
    return (
      <Flex
        direction="row"
        align="center"
        overrideStyle={{
          width: "45px",
          position: "relative",
        }}
        //@ts-ignore
        id="s1"
      >
        {isMulti && selectedOption.length > 0 && getLeftComponent() ? (
          <Close
            className={`${classes.multiCloseIconStyle} ${overrideCloseIconClass}`}
            style={{ alignSelf: "center" }}
            onClick={() => {
              setSelectedOption([]);
              handleChange && handleChange({ res: [] });
            }}
            id="s1"
            data-testid="selectbox_clear_options"
          />
        ) : (
          ""
        )}
        <Flex direction="row" overrideClass={classes.arrowClass}>
          {/* <StraightLine
            imgStyle={{
              height: "100%",
              marginLeft: isMulti ? "" : `${Theme.margin}%`,
              ...overrideStraightLineStyle,
            }}
            id="s1"
          /> */}

          <DownAngleArrow
            style={{ alignSelf: "center" }}
            className={`${arrowStyle} ${overrideArrowClass}`}
            id="s1"
          />
        </Flex>
      </Flex>
    );
  };
  const parentToggleHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    //@ts-ignore
    if (e.target?.id === "s1") {
      if (!isOptionsVisible && inputRef?.current) {
        //@ts-ignore
        inputRef.current.focus();
      }
      toggleOption();
    } else {
      setIsOptionsVisible(false);
    }
  };
  useEffect(() => {
    const closeDropDown = (e: React.ChangeEvent<HTMLElement>) => {
      if (e.target?.id !== "s1") setIsOptionsVisible(false);
    };
    //@ts-ignore
    document.body.addEventListener("click", closeDropDown);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        padding: 2,
        boxSizing: "border-box",
        ...parentContainerStyle,
      }}
      onClick={(e) => (disabled ? null : parentToggleHandler(e))}
      data-action="parent"
      id="s1"
      className={parentContainerClass}
    >
      <Flex
        direction="column"
        overrideStyle={{ ...containerStyle }}
        overrideClass={`${classes.container} ${containerClass}`}
        id="s1"
      >
        {showLabel && (
          <Label
            label={label}
            required={required}
            labelStyle={labelStyle}
            id="s1"
          />
        )}
        <form
          onSubmit={onSubmitHandler}
          id="s1"
          data-testid="form"
          style={{ width: "100%" }}
        >
          <Flex
            direction="row"
            justify="between"
            align="center"
            overrideClass={` ${mainContainer} ${overrideMainContainerClass} ${
              error ? classes.borderRed : ""
            } ${disabled ? classes.disabledCheckbox : ""} ${
              isOptionsVisible ? selectBoxContainer : ""
            } `}
            overrideStyle={{
              ...overrideMainContainerStyle,
              backgroundColor: disabled ? disabledColor : "",
            }}
            id="s1"
          >
            <Flex
              overrideStyle={{
                width: "90%",
              }}
              wrap={true}
              align="center"
              id="s1"
            >
              {isMulti && selectedOption.length > 0 && getLeftComponent()}
              <input
                type={"text"}
                className={`${inputClass}`}
                value={searchValue}
                style={{ backgroundColor: disabled ? disabledColor : "" }}
                // defaultValue={getPathData(selectedOption, [0, "value"], "")}
                placeholder={
                  isMulti
                    ? selectedOption.length > 0
                      ? ""
                      : placeholder
                    : getPathData(selectedOption, [0, labelKey], placeholder)
                }
                onChange={(e) => onChangeHandler(e.target.value)}
                ref={inputRef}
                autoComplete="off"
                name="select_box"
                disabled={disabled}
                id="s1"
                data-testid={testID}
                {...rest}
              />
            </Flex>
            {getRightComponent()}
          </Flex>
        </form>
        {isOptionsVisible && (
          <Flex
            direction="column"
            overrideStyle={overrideOptionContStyle}
            overrideClass={` ${optionContainer} ${overrideOptionContClass}`}
          >
            {selectArray.length === 0 ? (
              <Typography
                color={Colors.LABEL_COLOR}
                overrideClass={noOptionsClass}
              >
                No data available
              </Typography>
            ) : (
              selectArray.map((item, key) => {
                const value = getPathData(item, [valueKey], "");
                const isSelected =
                  selectedOption.length > 0 &&
                  selectedOption.findIndex(
                    (res) => getPathData(res, [valueKey], null) === value,
                  ) != -1;
                const isVisible = !(isMulti && isSelected);
                const label = getPathData(item, [labelKey], "");
                return (
                  isVisible && (
                    <Flex
                      overrideClass={` ${optionClass} ${
                        isSelected && !isMulti ? selectedOptionClass : ""
                      } ${overrideOptionClass}`}
                      key={key}
                      data-action="options"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onOptionClicked(item);
                      }}
                      data-testid={`selectbox_${key}`}
                    >
                      <Typography
                        overrideClass={`${overrideTypographyStyle} ${typographyStyle}`}
                      >
                        {label}
                      </Typography>
                    </Flex>
                  )
                );
              })
            )}
          </Flex>
        )}
      </Flex>
      {error ? (
        <Typography color={Colors.RED} testID={`${testID}_error`}>
          {errorMsg}
        </Typography>
      ) : helpText ? (
        <Typography color={helpTextColor} testID={`${testID}_helptext`}>
          {helpText}
        </Typography>
      ) : null}
    </div>
  );
};

SelectBox.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  containerClass: PropTypes.string,
  overrideMainContainerClass: PropTypes.string,
  overrideSelectBoxClass: PropTypes.string,
  overrideOptionContClass: PropTypes.string,
  overrideCloseIconClass: PropTypes.string,
  overrideArrowClass: PropTypes.string,
  overrideOptionClass: PropTypes.string,
  overrideSelectLabelClass: PropTypes.string,
  overrideSelectOptionClass: PropTypes.string,
  overrideTypographyStyle: PropTypes.string,
  overrideMainContainerStyle: PropTypes.object,
  overrideOptionContStyle: PropTypes.object,
  overrideStraightLineStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  dropDownHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isCustom: PropTypes.bool,
  handleChange: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  helpTextColor: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
  ]),
  inputType: PropTypes.oneOf(["single_value", "array_value"]),
  extraRefreshProps: PropTypes.array,
  responseType: PropTypes.oneOf(["single_value", "array_value"]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  parentContainerStyle: PropTypes.object,
  parentContainerClass: PropTypes.string,
  disabledColor: PropTypes.string,
  testID: PropTypes.string,
};

export default SelectBox;
