import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import Colors from "Global/colors";
import { dtutil, validateRegex } from "Utils/helpers";
import Label from "./label";
import Flex from "./flex";
import Typography from "./typography";
import { InputBoxPropType } from "./componentTypes";
const useStyles = createUseStyles((theme: any) => ({
  inputStyle: {
    width: "100%",
    padding: `${theme.padding}px ${theme.padding + 6}px`,
    height: "38px",
    boxSizing: "border-box",
    border: `1px solid ${Colors.BORDERCOLOR}`,
    transition: "0.1s",
    outline: "none",
    borderRadius: theme.borderRadius,
    color: Colors.SECONDARY,
    fontFamily: "Poppins-Regular",
    "&:focus": {
      border: `2px solid ${theme?.primaryColor}`,
    },
  },

  borderRed: {
    border: `1px solid ${Colors.RED}`,
  },
  container: {
    border: "none",
    width: "100%",
  },
  inputBoxContainer: {
    height: "100%",
  },
}));
const InputBox: React.FC<InputBoxPropType> = (props) => {
  const {
    handleChange = null,
    format = "text",
    name = "inputbox",
    error = false,
    errorMsg = "",
    value = "",
    label = "",
    placeholder = "",
    disabled = false,
    required = false,
    inputBoxStyle = {},
    containerStyle = {},
    labelStyle = {},
    containerClass = "",
    inputClass = "",
    regexExp = null,
    helpText = null,
    showLabel = true,
    maxLength = null,
    overrideRef = null,
    leftComponent = null,
    rightComponent = null,
    testID = "inputboxID",
    helpTextColor = Colors.RED,
    errorTextColor = Colors.RED,
    minDate = dtutil(
      //@ts-ignore
      new Date(),
    ).format("YYYY-MM-DD"),
    maxDate = null,
    onKeyDownHandler = null,
    type = "text",
    startsWithZero = false,
    overrideInputBoxConatiner = {},
    labelStyleClass = "",
    overrideMainContainer = {},
    textInputLabelColor = "",
    ...extraProps
  } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [props.value, props.disabled]);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputData = e.target.value;
    if (
      format === "number" &&
      inputData !== "" &&
      inputData.startsWith("0") &&
      inputData.length > 1 &&
      !startsWithZero
    ) {
      inputData = "1";
    }
    if (maxLength && Math.abs(inputData.length - 1) === maxLength) {
      return;
    }
    if (format === "number") {
      const numbers = /^\d+$/;
      if (inputData === "") {
        setInputValue(inputData);
        handleChange && handleChange(inputData, e);
        return;
      }
      if (!inputData.match(numbers)) {
        handleChange && handleChange(props.value, e);
        return;
      }
    }
    if (format === "decimal") {
      const numbers = /^\d*(\.)?(\d{0,20})?$/;
      if (inputData === "") {
        setInputValue(inputData);
        handleChange && handleChange(inputData, e);
        return;
      }
      if (!inputData.match(numbers)) {
        handleChange && handleChange(props.value, e);
        return;
      }
    }
    if (!regexExp) {
      setInputValue(inputData);
      handleChange && handleChange(inputData, e);
    } else {
      const isValid = validateRegex(inputData, regexExp);
      if (inputData === "") {
        setInputValue(inputData);
        handleChange && handleChange(inputData, { error: false }, e);
        return;
      }
      if (!isValid) {
        handleChange && handleChange(inputValue, { error: true }, e);
        return;
      } else {
        handleChange && handleChange(inputData, { error: false }, e);
        setInputValue(inputData);
      }
    }
  };
  const handleOnKeyDown = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onKeyDownHandler) {
      onKeyDownHandler();
    } else if (type === "date") {
      e.preventDefault();
      return false;
    }
  };
  const { container, inputStyle, borderRed, inputBoxContainer } = classes;
  return (
    <div style={{ width: "100%", ...overrideMainContainer }}>
      <Flex
        direction="column"
        overrideStyle={{ ...containerStyle }}
        overrideClass={`${container} ${containerClass}`}
      >
        {showLabel && (
          <Label
            label={label}
            required={required}
            labelStyle={labelStyle}
            overrideLabelClass={labelStyleClass}
            labelColour={textInputLabelColor}
          />
        )}
        <Flex
          justify="center"
          align="center"
          overrideClass={`${inputBoxContainer}`}
          overrideStyle={{ ...overrideInputBoxConatiner }}
        >
          <>
            {leftComponent && leftComponent()}
            <input
              type={type}
              name={name}
              className={` ${inputStyle} ${inputClass} ${
                error ? borderRed : ""
              } `}
              value={inputValue}
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              //@ts-ignore
              onChange={disabled ? null : onChangeHandler}
              style={{ ...inputBoxStyle }}
              data-testid={testID}
              maxLength={maxLength ?? undefined}
              //@ts-ignore
              min={minDate}
              //@ts-ignore
              max={maxDate}
              //@ts-ignore
              onKeyDown={handleOnKeyDown}
              {...extraProps}
              ref={overrideRef}
            />
            {rightComponent && rightComponent()}
          </>
        </Flex>
        {error ? (
          <Typography color={errorTextColor} testID={`${testID}_error`}>
            {errorMsg}
          </Typography>
        ) : helpText ? (
          <Typography color={helpTextColor} testID={`${testID}_helptext`}>
            {helpText}
          </Typography>
        ) : null}
      </Flex>
    </div>
  );
};

export default InputBox;
