import { createUseStyles } from "react-jss";
import Colors from "Global/colors";
import { ButtonPropType } from "./componentTypes";
// const colorCode = {
//   primary: Theme.primaryColor,
//   secondary: Theme.secondaryColor,
//   tertiary: Colors.RED,
// };
// @ts-expect-error
const useStyles = createUseStyles((theme: any) => ({
  // btnSm: {
  // height: "max-content",
  // width: "max-content",
  // padding: theme?.padding,
  // borderRadius: theme?.borderRadius,
  // border: "none",
  // boxSizing: "border-box",
  // },
  // btnMd: {
  //   width: "max-content",
  //   height: "max-content",
  //   padding: theme?.padding + 2,
  //   borderRadius: theme?.borderRadius,
  //   border: "none",
  //   boxSizing: "border-box",
  // },
  // btnLg: {
  //   width: "max-content",
  //   height: "max-content",
  //   padding: theme?.padding + 6,
  //   borderRadius: theme?.borderRadius,
  //   border: "none",
  //   boxSizing: "border-box",
  // },

  // btnpm: {
  //   backgroundColor: theme?.primaryColor,
  //   color: Colors.WHITE,
  // },

  // btnsc: {
  //   backgroundColor: theme?.secondaryColor,
  //   color: Colors.WHITE,
  // },

  // btnter: {
  //   backgroundColor: Colors.RED,
  //   color: Colors.WHITE,
  // },
  btnCommon: {
    height: "max-content",
    width: "max-content",
    padding: theme?.padding + 2,
    borderRadius: theme?.borderRadius,
    border: "none",
    boxSizing: "border-box",
  },
  btnfill: {
    backgroundColor: ({ btnBackgroundColor }) => btnBackgroundColor,
    color: ({ btnColor }) => btnColor,
    border: ({ btnBackgroundColor }) => `1px solid ${btnBackgroundColor}`,
    // @ts-expect-error
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-expect-error
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btnout: {
    backgroundColor: "transparent",
    color: ({ btnColor }) => btnColor,
    border: ({ btnColor }) => `1px solid ${btnColor}`,
    // @ts-expect-error
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-expect-error
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btninfo: {
    backgroundColor: Colors.BLACK,
    border: `1px solid ${Colors.BLACK}`,
    color: Colors.WHITE,
    // @ts-expect-error
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-expect-error
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btnwarn: {
    backgroundColor: Colors.YELLOW,
    border: `1px solid ${Colors.YELLOW}`,
    color: Colors.BLACK,
    // @ts-expect-error
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-expect-error
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btndanger: {
    backgroundColor: Colors.RED,
    border: `1px solid ${Colors.RED}`,
    color: Colors.WHITE,
    // @ts-expect-error
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-expect-error
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btnlink: {
    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.BLACK}`,
    color: Colors.BLACK,
    // @ts-expect-error
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-expect-error
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btnerr: {
    backgroundColor: "transparent",
    border: `1px solid ${Colors.RED}`,
    color: Colors.RED,
    // @ts-expect-error
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-expect-error
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },
}));

const Button: React.FC<ButtonPropType> = (props) => {
  const {
    children,
    type = "button",
    onClick = null,
    btnType = "filled",
    overrideStyle = {},
    disabled = false,
    buttonClass = "",
    testID = "btnId",
    overrideBtnClass = "",
    btnBackgroundColor = Colors.PRIMARY,
    btnColor = Colors.SECONDARY,
    ...extraProps
  } = props;
  const classes = useStyles({
    ...props,
    //@ts-ignore
    btnBackgroundColor,
    btnColor,
    disabled,
  });
  const {
    // btnSm,
    // btnMd,
    // btnLg,
    // btnpm,
    // btnsc,
    // btnter,
    btnfill,
    btnout,
    btninfo,
    btnwarn,
    btndanger,
    btnlink,
    btnerr,
    btnCommon,
  } = classes;
  const styledObj = {
    // size: {
    //   small: btnSm,
    //   medium: btnMd,
    //   large: btnLg,
    // },
    // color: {
    //   primary: btnpm,
    //   secondary: btnsc,
    //   tertiary: btnter,
    // },
    style: {
      filled: btnfill,
      outline: btnout,
      info: btninfo,
      warning: btnwarn,
      danger: btndanger,
      link: btnlink,
      error: btnerr,
    },
  };
  // ${
  //   // @ts-expect-error
  //   styledObj.size[size]
  // }

  // ${
  //   // @ts-expect-error
  //   styledObj.color[color]
  // }
  return (
    <button
      className={`
        ${buttonClass}
        ${btnCommon}
        ${styledObj.style[btnType]} 

        ${overrideBtnClass}
        `}
      //@ts-ignore
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{
        cursor: "pointer",
        ...overrideStyle,
      }}
      data-testid={testID}
      {...extraProps}
    >
      {children}
    </button>
  );
};
export default Button;
