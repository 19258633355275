import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { GridPropType } from "./componentTypes";
const useStyles = createUseStyles((theme) => ({
  grid: {
    boxSizing: "border-box",
    display: "grid",
    // @ts-expect-error
    gridRowGap: theme.margin,
    // @ts-expect-error
    gridColumnGap: theme.margin * 2 + 4,
    // @ts-expect-error
    gridAutoRows: (props) => props?.rowsValue,
    // @ts-expect-error
    gridTemplateRows: (props) => props?.rowsValue,
    width: "100%",
  },
}));
const Grid = (props: GridPropType) => {
  const {
    children,
    overrideStyle = {},
    minWidth = 300,
    maxWidth = "1fr",
    responsive = true,
    rowsValue = "auto",
    testID = "gridId",
    overrideGridContainer = "",
    ...rest
  } = props;
  const classes = useStyles({
    ...props,
    // @ts-expect-error
    rowsValue,
  });
  const { grid } = classes;
  return (
    <div
      className={`${grid} ${overrideGridContainer}`}
      style={{
        gridTemplateColumns: responsive
          ? `repeat(auto-fit, minmax(${minWidth}px, ${maxWidth}))`
          : "1fr",
        ...overrideStyle,
      }}
      data-testid={testID}
      {...rest}
    >
      {children}
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  overrideStyle: PropTypes.object,
  responsive: PropTypes.bool,
  testID: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  overrideGridContainer: PropTypes.string,
};

export default Grid;
