import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import Flex from "./flex";
import Button from "./button";
import { getPathData } from "Utils/helpers";
import { Colors } from "Global/colors";
import Typography from "./typography";
import { ModalPropType } from "./componentTypes";
const useStyles = createUseStyles((theme: any) => ({
  modal__wrap: {
    position: "fixed",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "0 auto",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100%",
    zIndex: "100",
    overflowX: "hidden",
    backgroundColor: Colors.OVERLAY,
    pointerEvents: "none",
    opacity: 0,
  },

  visible: {
    pointerEvents: "auto",
    opacity: 1,
    transition: "all 30ms ease-in-out",
  },

  modal: {
    overflowY: "hidden",
    overflowX: "hidden",
    margin: "0 auto",
    borderRadius: theme.borderRadius,
    backgroundColor: Colors.WHITE,
    alignSelf: "center",
    width: "90%",
    height: "50%",
    "@media (min-width: 1024px)": {
      // @ts-expect-error
      width: (props) => getPathData(ModalStyle, [props?.size, "width"], "100%"),
      height: (props) =>
        // @ts-expect-error
        getPathData(ModalStyle, [props?.size, "height"], "100%"),
    },
  },
  modalCont: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
  },
  modalHeader: {
    boxShadow: "0 1px 1px rgba(57, 63, 72, 0.3)",
    display: "flex",
    paddingLeft: theme.padding * 2 + 10,
    margin: theme.margin * 0,
    position: "sticky",
    height: (props) =>
      // @ts-expect-error
      getPathData(headerStyle, [props?.size, "height"], "100%"),
  },
  modalFooter: {
    boxShadow: "1px rgba(57, 63, 72, 0.3)",
    borderTop: "1px solid rgb(211,211,211)",
    width: "100%",
    height: "60px",
    paddingLeft: theme.padding * 2 + 10,
    margin: theme.margin * 0,
  },
  closeIcon: {
    width: "30px",
    height: "30px",
    margin: theme.margin,
    cursor: "pointer",
  },
}));

const ModalStyle = {
  extraSmall: {
    height: "35%",
    width: "35%",
  },
  small: {
    height: "40%",
    width: "40%",
  },
  medium: {
    width: "60%",
    height: "60%",
  },
  large: {
    height: "80%",
    width: "80%",
  },
};
const headerStyle = {
  extraSmall: {
    height: "40px",
  },
  small: {
    height: "50px",
  },
  medium: {
    height: "70px",
  },
  large: {
    height: "90px",
  },
};

const Modal = (props: ModalPropType) => {
  const {
    open = false,
    children,
    backdropStyle = {},
    modalStyle = {},
    headerStyle = {},
    onClose = null,
    title = "",
    titleComponent = null,
    buttonHandler,
    footerComponent = null,
    size = "medium",
    showFooter = false,
    overrideCloseStyle = "",
    overrideModalCont = "",
    overrideModalParent = "",
    overrideModalHeader = "",
    overrideModalFooter = "",
    modalParentStyle = {},
    testID = "modalID",
    closeIcontTestId = "modalCloseIcon",
    showHeader = true,
  } = props;

  const classes = useStyles({
    ...props,
    //@ts-ignore
    size,
  });

  const {
    visible,
    modal__wrap,
    modal,
    modalCont,
    modalHeader,
    modalFooter,
    closeIcon,
  } = classes;

  const modalRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    if (modalRef.current !== null) {
      if (open) {
        modalRef?.current.classList.add(visible);
      } else {
        modalRef?.current.classList.remove(visible);
      }
    }
  }, [open]);
  return (
    <React.Fragment>
      <Flex
        innerRef={modalRef}
        overrideStyle={backdropStyle}
        overrideClass={modal__wrap}
      >
        <Flex
          justify="between"
          direction="column"
          overrideClass={`${modal} ${overrideModalParent}`}
          overrideStyle={modalParentStyle}
          data-testid={testID}
        >
          {showHeader ? (
            <Flex
              justify="between"
              align="center"
              overrideStyle={{ ...headerStyle }}
              overrideClass={`${modalHeader} ${overrideModalHeader}`}
            >
              <>
                {titleComponent ? (
                  titleComponent()
                ) : (
                  <Typography testID="modalHeaderTitle" type="h1">
                    {title}
                  </Typography>
                )}
                <img
                  src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTIgMTAuOTMgNS43MTktNS43MmMuMTQ2LS4xNDYuMzM5LS4yMTkuNTMxLS4yMTkuNDA0IDAgLjc1LjMyNC43NS43NDkgMCAuMTkzLS4wNzMuMzg1LS4yMTkuNTMybC01LjcyIDUuNzE5IDUuNzE5IDUuNzE5Yy4xNDcuMTQ3LjIyLjMzOS4yMi41MzEgMCAuNDI3LS4zNDkuNzUtLjc1Ljc1LS4xOTIgMC0uMzg1LS4wNzMtLjUzMS0uMjE5bC01LjcxOS01LjcxOS01LjcxOSA1LjcxOWMtLjE0Ni4xNDYtLjMzOS4yMTktLjUzMS4yMTktLjQwMSAwLS43NS0uMzIzLS43NS0uNzUgMC0uMTkyLjA3My0uMzg0LjIyLS41MzFsNS43MTktNS43MTktNS43Mi01LjcxOWMtLjE0Ni0uMTQ3LS4yMTktLjMzOS0uMjE5LS41MzIgMC0uNDI1LjM0Ni0uNzQ5Ljc1LS43NDkuMTkyIDAgLjM4NS4wNzMuNTMxLjIxOXoiLz48L3N2Zz4="
                  className={`${closeIcon} ${overrideCloseStyle}`}
                  // @ts-expect-error
                  onClick={onClose}
                  data-testid={closeIcontTestId}
                  alt="modal-close-icon"
                />
              </>
            </Flex>
          ) : null}
          <Flex
            overrideStyle={modalStyle}
            overrideClass={`${modalCont} ${overrideModalCont}`}
          >
            {children}
          </Flex>
          {showFooter ? (
            <Flex
              justify="between"
              align="center"
              overrideClass={`${modalFooter} ${overrideModalFooter}`}
            >
              {footerComponent ? (
                footerComponent()
              ) : (
                <Button btnType="filled" onClick={buttonHandler}>
                  Click
                </Button>
              )}
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default Modal;
