import React, { useState, useEffect, lazy } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Theme from "Global/theme";
import HomeErrorBoundary from "./ErrorBoundary/homeErrorBoundary";
import { ThemeProvider } from "react-jss";
import { Flex, Loader } from "./Component";
export const ThemeContext = React.createContext(null);
const RootContext = lazy(() => import("./Context/rootContext"));
const PrivateRoute = lazy(() => import("./Auth/privateRoute"));
const NotFound = lazy(() => import("./Component/notFound"));
const Layout = lazy(() => import("./Container/Home/layout"));
const HomePage = lazy(() => import("./Container/Home/homePage"));
const Login = lazy(() => import("./Container/Login/loginScreen"));
const Categories = lazy(() => import("./Container/Categories"));
const Partners = lazy(() => import("./Container/Partners"));
const Roles = lazy(() => import("./Container/Roles"));
const Permissions = lazy(() => import("./Container/Permissions"));
const Users = lazy(() => import("./Container/Users"));
const LoginCallback = lazy(() => import("./Container/Login/loginCallback"));
const Reports = lazy(() => import("./Container/Reports"));
const ResetPassword = lazy(() => import("./Container/Login/resetPassword"));

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <React.Suspense
      fallback={
        <Flex
          overrideStyle={{ height: "100vh" }}
          justify="center"
          align="center"
        >
          <Loader loaderMsg="Loading..." />
        </Flex>
      }
    >
      {children}
    </React.Suspense>
  );
};
const App = () => {
  const [isLoading, setIsLoading] = useState({
    loader: true,
    msg: "Loading...",
  });
  const { loader, msg } = isLoading;
  useEffect(() => {
    setIsLoading((prev) => ({ ...prev, loader: false }));
  }, []);
  return (
    <HomeErrorBoundary>
      <ThemeProvider theme={Theme}>
        {loader ? (
          <Flex
            direction="row"
            justify="center"
            align="center"
            overrideStyle={{ height: "100vh" }}
          >
            <Loader loaderMsg={msg} />
          </Flex>
        ) : (
          <Router>
            <Wrapper>
              <RootContext>
                <Routes>
                  <Route
                    element={
                      <Wrapper>
                        <PrivateRoute />
                      </Wrapper>
                    }
                  >
                    <Route
                      element={
                        <Wrapper>
                          <Layout />
                        </Wrapper>
                      }
                    >
                      <Route
                        path="/"
                        element={
                          <Wrapper>
                            <HomePage />
                          </Wrapper>
                        }
                      />
                      <Route
                        path="/categories"
                        element={
                          <Wrapper>
                            <Categories />
                          </Wrapper>
                        }
                      />
                      <Route
                        path="/partners"
                        element={
                          <Wrapper>
                            <Partners />
                          </Wrapper>
                        }
                      />
                      <Route
                        path="/permissions"
                        element={
                          <Wrapper>
                            <Permissions />
                          </Wrapper>
                        }
                      />
                      <Route
                        path="/roles"
                        element={
                          <Wrapper>
                            <Roles />
                          </Wrapper>
                        }
                      />
                      <Route
                        path="/users"
                        element={
                          <Wrapper>
                            <Users />
                          </Wrapper>
                        }
                      />
                      <Route
                        path="/reports"
                        element={
                          <Wrapper>
                            <Reports />
                          </Wrapper>
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="*"
                    element={
                      <Wrapper>
                        <NotFound />
                      </Wrapper>
                    }
                  />
                  <Route
                    index
                    path="/login"
                    element={
                      <Wrapper>
                        <Login />
                      </Wrapper>
                    }
                  />
                  <Route
                    index
                    path="/callback"
                    element={
                      <Wrapper>
                        <LoginCallback />
                      </Wrapper>
                    }
                  />
                  <Route
                    index
                    path="/create-password"
                    element={
                      <Wrapper>
                        <ResetPassword isCreatePassword={true} />
                      </Wrapper>
                    }
                  />
                  <Route
                    index
                    path="/reset-password"
                    element={
                      <Wrapper>
                        <ResetPassword isCreatePassword={false} />
                      </Wrapper>
                    }
                  />
                </Routes>
              </RootContext>
            </Wrapper>
          </Router>
        )}
      </ThemeProvider>
    </HomeErrorBoundary>
  );
};

export default App;
