import ErrorBoundary from "ErrorBoundary/homeErrorBoundary";
import Colors from "Global/colors";
import Flex from "./flex";
import Label from "./label";
import Typography from "./typography";
import DatePicker from "react-datepicker";
import { createUseStyles } from "react-jss";
import { DatePickerPropType } from "./componentTypes";
import "react-datepicker/dist/react-datepicker.css";
import { DownAngleArrow } from "Assets";
const useStyles = createUseStyles((theme: any) => ({
  customDateInput: {
    position: "relative",
    "& .react-datepicker__navigation--next": {
      "& span": {
        "&::before": {
          borderColor: `${Colors.PRIMARY}!important`,
        },
      },
    },
    "& .react-datepicker-wrapper": {
      width: "100%",
    },
    "& .react-datepicker__navigation--previous": {
      "& span": {
        "&::before": {
          borderColor: `${Colors.PRIMARY}!important`,
        },
      },
    },
    "& .react-datepicker__day--selected": {
      backgroundColor: Colors.PRIMARY,
    },
    "& .react-datepicker__day-names": {
      ...theme.typography.p,
    },
    "& .react-datepicker__month": {
      ...theme.typography.p,
    },
    "& .react-datepicker__week": {
      ...theme.typography.p,
    },
  },
  arrowClass: {
    height: 20,
    borderLeft: `1px solid ${Colors.BORDERCOLOR}`,
    position: "absolute",
    right: -10,
    top: "50%",
    width: "initial",
    transform: "translateY(-50%)",
    paddingRight: theme.padding * 1.5,
    paddingLeft: theme.padding * 1.5,
    "& img": {
      width: theme.padding * 2,
    },
  },
  dateInputClass: {
    minHeight: 38,
    fontSize: 14,
    borderRadius: 4,
    color: theme?.secondaryColor,
    border: (props) =>
      `1px solid ${
        // @ts-ignore
        props?.error ? Colors.RED : Colors.BORDERCOLOR
      }`,
    padding: "0px 14px",
    width: "92%",
    "& ::placeholder": {
      fontSize: 14,
      color: Colors.SECONDARY,
    },
    "&:focus-visible": {
      outline: `2px solid ${Colors.PRIMARY}!important`,
    },
    "@media (max-width: 767px)": {
      width: "94%!important",
    },
    "&:disabled": {
      background: Colors.DISABLED,
    },
  },
  errorDate: {
    border: `1px solid ${Colors.RED}`,
  },
  rightDateInputClass: {
    width: "94%!important",
  },
  arrowStyle: {
    width: "13px",
    height: "13px",
    marginRight: (
      props,
      // @ts-ignore
    ) => (props.isMulti ? "" : `${theme.margin * 2}%`),
    marginLeft: (
      props,
      // @ts-ignore
    ) => (props.isMulti ? `${theme.margin * 2}%` : ""),
    opacity: (
      props,
      // @ts-ignore
    ) => (props?.disabled ? 0.3 : 0.5),
    "&:hover": {
      opacity: (
        props,
        // @ts-ignore
      ) => (props?.disabled ? 0.3 : 1),
    },
  },
}));

const DatePickerComponent: React.FC<DatePickerPropType> = ({
  label = "",
  required = true,
  onChange,
  onBlur,
  disabled = false,
  value,
  error,
  errorMsg,
  selectsMultiple = true,
  withPortal = false,
  selectsRange = true,
  showLabel = true,
  showArrow = true,
  overrideStyleContainer = {},
  customClassName = "",
  ...rest
}) => {
  const classes = useStyles({
    // @ts-ignore
    error,
  });
  return (
    <Flex
      direction="column"
      overrideStyle={{ width: "100%", ...overrideStyleContainer }}
    >
      {showLabel && (
        <Label label={label} required={required} testID="dob_label" />
      )}
      <ErrorBoundary>
        <>
          <Flex
            direction="column"
            overrideClass={`${classes.customDateInput} ${customClassName}`}
          >
            <DatePicker
              selected={value}
              dateFormat="dd-MM-yyyy"
              placeholderText="DD-MM-YYYY"
              dropdownMode="select"
              showYearDropdown={true}
              showMonthDropdown={true}
              maxDate={new Date()}
              onBlur={onBlur}
              onChange={onChange}
              name="birth_date"
              className={classes.dateInputClass}
              disabled={disabled}
              data-testid="dob_component"
              withPortal={withPortal}
              selectsRange={selectsRange}
              {...rest}
            />

            {showArrow && (
              <Flex overrideClass={classes.arrowClass}>
                <DownAngleArrow
                  style={{ alignSelf: "center" }}
                  className={`${classes.arrowStyle}`}
                  id="s1"
                />
              </Flex>
            )}
          </Flex>
        </>
      </ErrorBoundary>
      {error && <Typography color={Colors.RED}>{errorMsg}</Typography>}
    </Flex>
  );
};

export default DatePickerComponent;
