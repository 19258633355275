import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { getPathData } from "Utils/helpers";
import Colors from "Global/colors";
import { TypographyTypes } from "./componentTypes";
// import { TypographyType } from "Global/theme";
const useStyles = createUseStyles((theme) => {
  return {
    typographyClass: (props: any) => ({
      ...getPathData(
        theme,
        ["typography", getPathData(props, ["type"], "")],
        {},
      ),
      userSelect: props?.userSelect,
      color: props?.disabled ? Colors.UNSELECTED : props?.color,
      wordWrap: "break-word",
      "&:hover": {
        textDecoration: props.type === "link" ? "underline" : "null",
        color:
          props.type === "link"
            ? //@ts-ignore
              theme?.primaryColor
            : props?.color,
      },
    }),
  };
});
const Typography = (props: TypographyTypes) => {
  const {
    children = null,
    overrideStyle = {},
    type = "p",
    color = Colors.SECONDARY,
    overrideClass = "",
    disabled = false,
    userSelect = "none",
    onClick = null,
    testID = "typographyID",
    ...rest
  } = props;
  const classes = useStyles({ ...props, type, color, disabled, userSelect });
  return (
    <div
      className={`${classes.typographyClass} ${overrideClass}`}
      style={{
        color: color !== "inherit" ? color : "inherit",
        ...overrideStyle,
      }}
      //@ts-ignore
      onClick={onClick}
      data-testid={testID}
      {...rest}
    >
      {children}
    </div>
  );
};
export default Typography;
