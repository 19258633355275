import {
  Audio,
  BallTriangle,
  Bars,
  Circles,
  Grid,
  Hearts,
  Oval,
  Puff,
  Rings,
  TailSpin,
  ThreeDots,
} from "react-loader-spinner";
import Flex from "./flex";
import { getPathData } from "Utils/helpers";
import Theme from "Global/theme";
import Colors from "Global/colors";
import Typography from "./typography";
import { LoaderPropType } from "./componentTypes";
import { createUseStyles } from "react-jss";
const useStyles = createUseStyles((theme: any) => ({
  containerClass: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));
const Loader = (props: LoaderPropType) => {
  const classes = useStyles();
  const {
    loaderMsg = "",
    loaderType = "Oval",
    overrideContainerClass = "",
    overrideContainerStyle = {},
    overrideLoaderClass = "",
    overrideLoaderStyle = {},
    loaderHeight = 50,
    loaderWidth = 50,
    strokeWidth = 4,
    strokeWidthSecondary = 4,
    overrideLoaderTextClass = "",
    overrideLoaderTextStyle = {},
    typographyColor = Colors.PRIMARY,
    testID = "loader" + loaderType,
    color = Colors.PRIMARY,
    ...rest
  } = props;
  const LoaderStyle = {
    Audio: (params: LoaderPropType) => <Audio {...params} />,
    BallTriangle: (params: LoaderPropType) => <BallTriangle {...params} />,
    Bars: (params: LoaderPropType) => <Bars {...params} />,
    Circles: (params: LoaderPropType) => <Circles {...params} />,
    Grid: (params: LoaderPropType) => <Grid {...params} />,
    Hearts: (params: LoaderPropType) => <Hearts {...params} />,
    Oval: (params: LoaderPropType) => (
      <Oval
        secondaryColor={Colors.PRIMARY_LIGHT}
        strokeWidth={strokeWidth}
        strokeWidthSecondary={strokeWidthSecondary}
        {...params}
      />
    ),
    Puff: (params: LoaderPropType) => <Puff {...params} />,
    Rings: (params: LoaderPropType) => <Rings {...params} />,
    TailSpin: (params: LoaderPropType) => <TailSpin {...params} />,
    ThreeDots: (params: LoaderPropType) => <ThreeDots {...params} />,
  };
  const LoaderComponent = getPathData(LoaderStyle, [loaderType], null);
  return (
    <Flex
      justify="center"
      align="center"
      overrideClass={`${classes.containerClass} ${overrideContainerClass}`}
      overrideStyle={{ textAlign: "center", ...overrideContainerStyle }}
      testID={testID}
    >
      <Flex
        direction="column"
        justify="center"
        align="center"
        overrideClass={overrideLoaderClass}
        overrideStyle={overrideLoaderStyle}
      >
        {LoaderComponent &&
          LoaderComponent({
            height: loaderHeight,
            width: loaderWidth,
            color,
            ...rest,
          })}
        <Typography
          color={typographyColor}
          overrideStyle={{
            marginTop: Theme.margin,
            ...overrideLoaderTextStyle,
          }}
          overrideClass={overrideLoaderTextClass}
        >
          {loaderMsg}
        </Typography>
      </Flex>
    </Flex>
  );
};

export default Loader;
