import React from "react";
import { createUseStyles } from "react-jss";
import { animated } from "@react-spring/web";
import { FlexPropType } from "./componentTypes";
const useStyles = createUseStyles({
  container: {
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    transition: "all 0.3s ease",
    fontFamily: "Poppins-Regular",
  },
  normalContainer: {
    width: "100%",
    boxSizing: "border-box",
    transition: "all 0.3s ease",
  },
});
const fetchValues = {
  between: "space-between",
  around: "space-around",
  center: "center",
  "flex-start": "flex-start",
  "flex-end": "flex-end",
  evenly: "space-evenly",
  inherit: "inherit",
  initial: "inital",
  stretch: "stretch",
  baseline: "baseline",
};
const Flex: React.FC<FlexPropType> = (props) => {
  const {
    children = null,
    wrap = false,
    justify = "flex-start",
    direction = "row",
    align = "flex-start",
    overrideStyle = {},
    overrideClass = "",
    innerRef = null,
    isFlex = true,
    testID = "flexBoxID",
    ...rest
  } = props;
  const classes = useStyles();
  if (isFlex) {
    return (
      <animated.div
        className={`${classes.container} ${overrideClass}`}
        style={{
          flexDirection: direction,
          //@ts-ignore
          justifyContent: fetchValues[justify],
          //@ts-ignore
          alignItems: fetchValues[align],
          flexWrap: wrap ? "wrap" : "nowrap",
          ...overrideStyle,
        }}
        ref={innerRef}
        data-testid={testID}
        {...rest}
      >
        {children}
      </animated.div>
    );
  } else {
    return (
      <animated.div
        className={`${classes.normalContainer} ${overrideClass}`}
        style={{
          ...overrideStyle,
        }}
        ref={innerRef}
        data-testid={testID}
        {...rest}
      >
        {children}
      </animated.div>
    );
  }
};
export default Flex;
