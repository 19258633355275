import { createUseStyles } from "react-jss";
import Button from "./button";
import { ErrorCardProptype } from "./componentTypes";
import Flex from "./flex";
import Typography from "./typography";
const useStyles = createUseStyles((theme: any) => ({
  errorIcon: {
    width: 100,
    height: 100,
    backgroundSize: "cover",
  },
  overrideBtnClass: {
    marginTop: theme.margin * 2,
    width: 200,
  },
}));
const ErrorCard = (props: ErrorCardProptype) => {
  const {
    message = "Something went wrong, Please try after sometime.",
    overrideStyle = {},
    buttonLabel = "GO BACK",
    showButton = false,
    btnAction = () => null,
  } = props;
  const classes = useStyles();
  return (
    <Flex
      direction="column"
      overrideStyle={{ height: "100%", ...overrideStyle }}
      justify="center"
      align="center"
      testID="not_found"
    >
      <img
        src={process.env.PUBLIC_URL + "/assets/error.gif"}
        className={classes.errorIcon}
        loading="eager"
      />
      <Typography type="fontLarge">{message}</Typography>
      {showButton && (
        <Button
          testID="not_found_btn"
          overrideBtnClass={classes.overrideBtnClass}
          onClick={btnAction}
        >
          {buttonLabel}
        </Button>
      )}
    </Flex>
  );
};
export default ErrorCard;
